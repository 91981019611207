<template>
	<div class="mb-20">
		<div class="grid grid-cols-2 mb-4">
			<div class="col-span-1">
				<h2 class="text-2xl font-bold">Active Companies</h2>
			</div>
			<div class="col-span-1">
				<div class="relative">
					<input
						type="search"
						name="search"
						class="border border-solid border-blue-200 rounded placeholder-blue-200 outline-none pl-10 pr-2 py-2 text-xs w-full"
						placeholder="Search for company’s name, status, etc."
						v-model="searchQuery"
					/>
					<ion-icon
						name="search-outline"
						class="text-blue-200 absolute z-10 text-lg ml-3 left-0"
						style="top: 50%; transform: translateY(-50%);"
					></ion-icon>
				</div>
			</div>
		</div>

		<div class="border border-solid border-blue-200">
			<div class="flex">
				<div
					class="duration-300"
					:class="{ 'w-full': !isOpen, 'w-1/2': isOpen }"
				>
					<datatable
						:index="true"
            :reverse-index="true"
						:data="companies"
						:columns="columns"
						:pageDetails="true"
						:onClick="click"
						:query="searchQuery"
						:className="tableRowClassName"
						:loading="loading"
						ref="table"
					/>
				</div>
				<div class="w-1/2 px-8 py-10 border-l border-blue-200" v-if="isOpen">
					<div class="animated animation-delay-300 fadeIn relative z-10">
						<div class="flex flex-row items-center justify-between mb-5">
							<h2 class="text-xl font-bold">
								{{ company.name }}
							</h2>
							<div class="dropdown">
								<a href="#!" class="flex flex-col ml-3" dropdown>
									<div class="h-1 w-1 bg-gray-400 mb-1 rounded-full"></div>
									<div class="h-1 w-1 bg-gray-400 mb-1 rounded-full"></div>
									<div class="h-1 w-1 bg-gray-400 rounded-full"></div>
								</a>
								<ul class="dropdown-menu right whitespace-no-wrap">
									<a
										href="#!"
										class="dropdown-menu-item text-red-500"
										@click.prevent="deleteCompany(company)"
										>Delete Company</a
									>
								</ul>
							</div>
						</div>
					</div>

					<div class="h-full overflow-y-auto">
						<div class="animated animation-delay-300 fadeIn">
							<div
								class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-1 xl:grid-cols-2 mb-10"
							>
								<div class="col-span-1">
									<div
										class="px-8 xl:px-12 py-8 border-t border-b border-l border-r md:border-r-0 lg:border-r xl:border-r-0 border-blue-200 relative"
									>
										<span
											class="absolute h-4 bg-blue-500 block left-0 ml-3 xl:ml-5"
											style="width: 3px"
										></span>
										<div class="flex justify-between items-center mb-4">
											<div class="text-xs">
												Total Employees
											</div>
										</div>
										<div class="text-sm font-medium">
											{{ noOfStaff }}
										</div>
									</div>
								</div>

								<div class="col-span-1">
									<div
										class="px-8 xl:px-12 py-8 md:border-t lg:border-t-0 xl:border-t border-b border-l border-r border-blue-200 relative"
									>
										<span
											class="absolute h-4 bg-orange-500 block left-0 ml-3 xl:ml-5"
											style="width: 3px"
										></span>
										<div class="flex justify-between items-center mb-4">
											<div class="text-xs">
												Total Pending
											</div>
										</div>
										<div class="text-sm font-medium">
											₦ 0.00
										</div>
									</div>
								</div>

								<div class="col-span-1">
									<div
										class="px-8 xl:px-12 py-8 border-b border-l border-r md:border-r-0 lg:border-r xl:border-r-0 border-blue-200 relative"
									>
										<span
											class="absolute h-4 bg-green-500 block left-0 ml-3 xl:ml-5"
											style="width: 3px"
										></span>
										<div class="flex justify-between items-center mb-4">
											<div class="text-xs">
												Total Loan Amount
											</div>
										</div>
										<div class="text-sm font-medium">
											₦ 0.00
										</div>
									</div>
								</div>

								<div class="col-span-1">
									<div
										class="px-8 xl:px-12 py-8 border-b border-l border-r border-blue-200 relative"
									>
										<span
											class="absolute h-4 bg-blue-800 block left-0 ml-3 xl:ml-5"
											style="width: 3px"
										></span>
										<div class="flex justify-between items-center mb-4">
											<div class="text-xs">
												Total Business Loan
											</div>
										</div>
										<div class="text-sm font-medium">
											₦ 0.00
										</div>
									</div>
								</div>

								<div
									class="col-span-1 md:col-span-2 lg:col-span-1 xl:col-span-2"
								>
									<div
										class="px-8 xl:px-12 py-8 border-b border-l border-r border-blue-200 relative"
									>
										<span
											class="absolute h-4 bg-blue-800 block left-0 ml-3 xl:ml-5"
											style="width: 3px"
										></span>
										<div class="flex justify-between items-center mb-4">
											<div class="text-xs">Agent</div>
										</div>
										<div class="text-sm font-medium">
											{{ agentName || company.agent_code || "N/A" }}
										</div>
									</div>
								</div>
							</div>

							<h2 class="text-xl font-bold mb-5">
								Recent Transactions
							</h2>

							<div class="border border-solid border-blue-200">
								<datatable
									:data="loans"
									:columns="transactionColumns"
									:loading="loading"
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			searchQuery: "",
			company: null,
			companies: [],
			loading: false,
			loadingCompany: false,
			columns: [
				{
					name: "name",
					th: "Company Name",
				},
				{
					name: "tier",
					th: "Company Tier",
					render: (company, tier) => `Tier ${tier}`,
				},
				{
					name: "employees",
					th: "No. Of Staff",
					render: (company) =>
						company.profile?.no_of_staff ? company.profile?.no_of_staff : 0,
				},
				{
					name: "profile",
					th: "Profile",
					render: (company, profile) => {
						if (profile) {
							return `<div class="rounded-sm border border-green-500 text-green-500 bg-green-100 text-xs px-3 py-2 inline-block">
									Updated
								</div>`;
						}
						return `<div class="rounded-sm border border-orange-500 text-orange-500 bg-orange-100 text-xs px-3 py-2 inline-block">
								Pending
							</div>`;
					},
				},
				{
					name: "created_at",
					th: "Onboarding Date",
					render: (row, created_at) =>
						this.$options.filters.dateFormat(created_at, "D dd M, Y h:ia"),
				},
				{
					name: "",
					th: "Action",
					render: (company) => `
							<div class="flex flex-row items-center justify-between">
								<button
								    class="px-8 py-3 bg-white hover:bg-blue-500 text-blue-500 hover:text-white border border-blue-500 rounded-sm text-xs outline-none focus:outline-none"
									  data-click="toUsers(${company.id})">
									  View All Users
								</button>
							</div>
						`,
				},
			],
			loans: [],
			transactionColumns: [
				{
					name: "created_at",
					th: "Time",
				},
				{
					name: "status",
					th: "Status",
					render: (transaction, status) => {
						switch (status) {
							case "approved":
								return `<div class="rounded-sm border border-green-500 text-green-500 bg-green-100 text-xs px-3 py-2 inline-block">
										Confirmed
									</div>`;
							case "declined":
								return `<div class="rounded-sm border border-orange-500 text-orange-500 bg-orange-100 text-xs px-3 py-2 inline-block">
										Pending
									</div>`;
							case "paid":
								return `<div class="rounded-sm border border-green-500 text-green-500 bg-green-100 text-xs px-3 py-2 inline-block">
										Confirmed
									</div>`;
							case "pending":
							default:
								return `<div class="rounded-sm border border-orange-500 text-orange-500 bg-orange-100 text-xs px-3 py-2 inline-block">
										Pending
									</div>`;
						}
					},
				},
				{
					align: "right",
					name: "loan_amount",
					th: "Amount",
					render: (transaction, amount) =>
						`- ₦ ${this.$options.filters.currency(amount)}`,
				},
			],
		};
	},
	computed: {
		agentName() {
			return this.company?.agent?.name;
		},
		isOpen() {
			return !!this.company;
		},
		noOfStaff() {
			return this.company.profile?.no_of_staff
				? this.company.profile?.no_of_staff
				: 0;
		},
	},
	beforeMount() {
		this.getCompanies();
	},
	mounted() {
		this.$options.listenForClick.apply(this);
	},
	methods: {
		click(company) {
			this.company = this.company === company ? null : company;
			this.$refs.table.renderData();
			if (this.company) {
				this.getCompanyLoans(company);
			}
		},
		async getCompanies() {
			this.loading = true;
			await this.$get({
				url: `${this.$baseurl}/companies`,
				headers: this.headers,
				success: (response) => {
					this.companies = response.data.data;
				},
			});
			this.loading = false;
		},
		async getCompanyLoans(company) {
			this.loadingCompany = true;
			await this.$get({
				url: `${this.$baseurl}/companies/staff/loans/${company.id}`,
				headers: this.headers,
				success: (response) => {
					this.loans = response.data.loans;
				},
			});
			this.loadingCompany = false;
		},
		async deleteCompany(company) {
			this.loading = true;
			await this.$post({
				url: `${this.$baseurl}/companies/delete/${company.id}`,
				headers: this.headers,
				success: (response) => {
					this.companies = response.data.data;
				},
			});
			this.loading = false;
		},
		tableRowClassName(row) {
			return row.id === this.company?.id ? "selected" : "";
		},
		toUsers(company_id) {
			this.$router.push({
				name: "companies-users",
				params: { company_id }
			});
		},
	},
};
</script>